<template>
<div class="list-widget list-dash-items" :widget-id="widget.id" :widget-type="widget.type">
    <Post group_id="1" col="col-12 m-2" :username="this.$store.state.user.username"></Post>
</div>
</template>

<script>
// این کامپوننت برای نمایش لیست ارسالی های کاربر استفاده میشود
import Post from '@/components/Posts/Post.vue';

export default {
    name: 'MyPosts',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        Post,
    },
    data() {
        return {
            posts: {
                id: undefined,
                length: undefined,
                created_at: undefined,
                user: {
                    username: undefined,
                },
            },
            responsive: {
                rightSection: true,
                leftSection: false,
            }
        }
    },
    methods: {
        // این متد وظیفه بازکردن مودال و هندل کردن کال بک مودال تنظیمات را بر عهده دارد
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/MyPosts.vue',
                config: {
                    data: {
                        title: this.widget.title,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);
            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;
                let widget = this.$helpers.unbindObject(this.widget);
                this.$helpers.saveWidget(widget);
            });
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
            } else if (mode == 2) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
            } else if (mode == 3) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = false;
            }
        },
    },
}
</script>
